import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import maxSize from 'popper-max-size-modifier';
import { PopperProps as MuiPopperProps } from '@mui/material/Popper';
import { MuiFade, MuiClickAwayListener } from '../../../../../modules/material';
import {
  StyledPopper,
  StyledPaper,
  StyledArrow,
  StyledContent,
  StyledBody,
  StyledFooter,
} from './styles';

export interface PopperProps
  extends Omit<MuiPopperProps, 'children'>,
    PropsWithChildren {
  timeout?: number;
  arrow?: true;
  footer?: ReactNode;
  onClose?: () => void;
}

export default function Popper(props: PopperProps) {
  const {
    arrow = true,
    transition = true,
    disablePortal = true,
    keepMounted = true,
    placement = 'bottom-end',
    timeout = 350,
    modifiers = [],
    anchorEl,
    children,
    onClose,
    footer,
    open,
    ...popperProps
  } = props;

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const handleClickAway = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const content = (
    <StyledContent>
      {arrow && <StyledArrow ref={setArrowRef} />}
      <StyledBody footer={Boolean(footer)}>{children}</StyledBody>
      {footer && <StyledFooter>{footer}</StyledFooter>}
    </StyledContent>
  );

  return (
    <StyledPopper
      modifiers={[
        maxSize,
        {
          name: 'applyMaxSize',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['maxSize'],
          fn({ state }) {
            const height = state.modifiersData.maxSize?.height;

            if (height) {
              state.styles.popper.maxHeight = `${height - 24}px`;
            }
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
        ...modifiers,
      ]}
      transition={transition}
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      placement={placement}
      open={open}
      keepMounted={keepMounted}
      {...popperProps}
    >
      {({ TransitionProps }) => (
        <MuiFade {...TransitionProps} timeout={timeout}>
          <StyledPaper paddingVariant="none" arrow={arrow}>
            {open ? (
              <MuiClickAwayListener onClickAway={handleClickAway}>
                {content}
              </MuiClickAwayListener>
            ) : (
              content
            )}
          </StyledPaper>
        </MuiFade>
      )}
    </StyledPopper>
  );
}
