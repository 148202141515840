import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '../../../library/Button';

export interface BasketViewFullButtonProps extends ButtonProps {}

export default function BasketViewFullButton(props: BasketViewFullButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      href="/view-basket"
      pathPrefix="purchase"
      color="primary"
      variant="outlined"
      fullWidth
      sx={{
        whiteSpace: 'nowrap',
      }}
      {...props}
    >
      {t('site.pricing.basket.button.view-full-basket.label')}
    </Button>
  );
}
