import { ReactNode } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Typography, { TypographyProps } from '../../library/Typography';

import { StyledLocationOnIcon } from './styles';

export interface LocationProps extends TypographyProps {
  location: ReactNode;
  isLocationIconEnabled?: boolean;
  locationIconProps?: SvgIconProps;
}

export default function Location(props: LocationProps) {
  const {
    location = [],
    isLocationIconEnabled = true,
    locationIconProps,
    ...locationProps
  } = props;

  return (
    <Typography
      component="span"
      variant="caption1"
      neutralColorIndex={700}
      opacity={0.7}
      {...locationProps}
    >
      {isLocationIconEnabled && (
        <StyledLocationOnIcon fontSize="small" {...locationIconProps} />
      )}
      {location}
    </Typography>
  );
}
