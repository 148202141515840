import { useRootSelector } from '../../../../../../../../store/hooks';
import { selectSitePricingPackagesMeta } from '../../../../../../store/features/packages';
import OrderDiscount from '../../../../OrderSummary/components/OrderDiscount';

export default function Discount() {
  const {
    totalPrice: { totalDiscount },
  } = useRootSelector(selectSitePricingPackagesMeta);

  if (!totalDiscount) {
    return null;
  }

  return <OrderDiscount totalDiscount={totalDiscount} />;
}
