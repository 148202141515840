import { styled } from '@mui/material/styles';
import Typography from '../../library/Typography';
import { PriceProps } from './index';

const styledPriceOptions = {
  shouldForwardProp: (prop: string) => !['lineThrough'].includes(prop),
};

export const StyledPrice = styled(
  Typography,
  styledPriceOptions,
)<PriceProps>((props) => {
  const { lineThrough } = props;

  return {
    ...(lineThrough && {
      textDecoration: 'line-through',
    }),
  };
});
