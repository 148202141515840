import { badgeClasses } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '../../../library/Badge';

export const StyledShoppingCartIcon = styled(ShoppingCartIcon)(({ theme }) => {
  return {
    display: 'flex',
    color: theme.palette.neutral[0],
  };
});

export const StyledBadge = styled(Badge)(({ theme }) => {
  return {
    [`& .${badgeClasses.badge}`]: {
      color: theme.palette.neutral[900],
    },
  };
});
