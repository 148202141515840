import { MuiPopper } from '../../../../../modules/material';
import { styled } from '@mui/material/styles';
import Paper from '../Paper';
import { PopperProps } from './index';

const styledPopperOptions = {
  shouldForwardProp: (prop: string) => !['arrow'].includes(prop),
};

export const StyledPopper = styled(
  MuiPopper,
  styledPopperOptions,
)(() => ({
  zIndex: 1200,
}));

export const StyledPaper = styled(
  Paper,
  styledPopperOptions,
)<Pick<PopperProps, 'arrow'>>(({ arrow }) => ({
  ...(arrow && { top: 'var(--payment-component-popper-arrow-size)' }),
  boxShadow: '2px 4px 13px 0px rgba(93, 93, 103, 0.13)',
  maxHeight: 'inherit',
}));

export const StyledArrow = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: 'calc(-1 * var(--payment-component-popper-arrow-size) / 2)',
  width: 'var(--payment-component-popper-arrow-size)',
  height: 'var(--payment-component-popper-arrow-size)',
  boxSizing: 'border-box',
  color: theme.palette.neutral[0],

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: 'currentColor',
    transform: 'rotate(45deg)',
  },
}));

export const StyledContent = styled('div')(() => ({
  maxHeight: 'inherit',
  overflowY: 'auto',
}));

const styledBodyOptions = {
  shouldForwardProp: (prop: string) => !['footer'].includes(prop),
};

export interface StyledBodyProps {
  footer?: boolean;
}

const popperPadding = 'var(--payment-component-popper-padding)';

export const StyledBody = styled(
  'div',
  styledBodyOptions,
)<StyledBodyProps>(({ footer }) => ({
  padding: footer ? `${popperPadding} ${popperPadding} 0` : popperPadding,
}));

export const StyledFooter = styled('div')(() => ({
  padding: 0,
}));
