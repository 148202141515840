import { styled } from '@mui/material/styles';
import { MuiStack } from '../../../../../../../../modules/material';
import { PackageCardProps } from './index';

const styledCardOptions = {
  shouldForwardProp: (prop: string) =>
    !['isMobile', 'hasBorder'].includes(prop),
};

export interface StyledCardProps {
  isMobile?: boolean;
  hasBorder?: boolean;
}

export const StyledCard = styled(
  MuiStack,
  styledCardOptions,
)<StyledCardProps>(({ theme, isMobile, hasBorder }) => {
  const mobilePadding = `16px 0 ${hasBorder ? '16px' : '0'} 0`;
  const desktopPadding = `12px 0 ${hasBorder ? '12px' : '0'} 0`;

  return {
    position: 'relative',
    width: '100%',
    padding: isMobile ? mobilePadding : desktopPadding,
    ...(hasBorder && {
      borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    }),
  };
});

export const StyledCardOverlay = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: theme.palette.neutral[0],
  opacity: 0.7,
  zIndex: 3,
}));

export const StyledPicture = styled(
  'div',
  styledCardOptions,
)<Pick<PackageCardProps, 'isMobile'>>(({ isMobile }) => ({
  width: '96px',
  height: '96px',

  ...(isMobile && {
    width: '88px',
    height: '88px',
  }),
}));
