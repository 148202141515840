import { styled } from '@mui/material/styles';
import { MuiIconButton } from '../../../../../modules/material';
import { IconButtonProps, IconButtonVariants } from './index';

export const StyledIconButton = styled(MuiIconButton)<IconButtonProps>(
  ({ variant, theme, size }) => {
    return {
      boxShadow: 'none',
      '&:disabled': {
        color: theme.palette.neutral[400],
        backgroundColor: theme.palette.neutral[50],
      },
      ...(size === 'small' && {
        padding: '6px',
      }),
      ...(variant === IconButtonVariants.square && {
        borderRadius: '4px',
        color: theme.palette.neutral[600],
        backgroundColor: theme.palette.neutral[0],
        outline: `1px solid ${theme.palette.neutral[200]}`,
        '&:hover': {
          outline: `1px solid ${theme.palette.neutral[900]}`,
          boxShadow: '2px 2px 12px 0px rgba(2, 16, 35, 0.10)',
        },
        '&:focus': {
          outline: `1px solid ${theme.palette.neutral[900]}`,
          backgroundColor: theme.palette.neutral[50],
        },
      }),
      ...(variant === IconButtonVariants.circular && {
        borderRadius: '45px',
        color: theme.palette.neutral[900],
        backgroundColor: theme.palette.neutral[100],
        '&:hover': {
          backgroundColor: theme.palette.neutral[200],
        },
        '&:focus': {
          outline: `1px solid ${theme.palette.neutral[0]}`,
          backgroundColor: theme.palette.neutral[200],
          boxShadow: '2px 2px 12px 0px rgba(2, 16, 35, 0.10)',
        },
      }),
      ...(variant === IconButtonVariants.icon && {
        padding: '0px',
        color: theme.palette.neutral[600],
        backgroundColor: theme.palette.neutral[0],
        '&:hover': {
          backgroundColor: theme.palette.neutral[0],
        },
        '&:focus': {
          backgroundColor: theme.palette.neutral[0],
        },
      }),
    };
  },
);
