import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { MuiTooltip } from '../../../../../modules/material';
import { TooltipProps, TooltipVisualStyleTypes } from './index';

const styledTooltipOptions = {
  shouldForwardProp: (prop: string) => !['visualStyle'].includes(prop),
};

export const StyledTooltip = styled(
  forwardRef(({ className, children, ...props }: TooltipProps, ref) => (
    <MuiTooltip {...props} classes={{ popper: className }} ref={ref}>
      {children}
    </MuiTooltip>
  )),
  styledTooltipOptions,
)(({ theme, visualStyle }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'var(--payment-component-tooltip-max-width)',
    padding: 'var(--payment-component-tooltip-padding)',
    borderRadius: 'var(--payment-component-tooltip-border-radius)',
    ...theme.typography.smallText,

    ...(visualStyle === TooltipVisualStyleTypes.default && {
      backgroundColor: theme.palette.neutral[600],
    }),
    ...(visualStyle === TooltipVisualStyleTypes.light && {
      backgroundColor: theme.palette.neutral[0],
      color: theme.palette.neutral[900],
      border: `1px solid ${theme.palette.neutral[200]}`,
    }),
  },

  [`& .${tooltipClasses.arrow}:before`]: {
    ...(visualStyle === TooltipVisualStyleTypes.default && {
      backgroundColor: theme.palette.neutral[600],
    }),
    ...(visualStyle === TooltipVisualStyleTypes.light && {
      backgroundColor: theme.palette.neutral[0],
      border: `1px solid ${theme.palette.neutral[200]}`,
    }),
  },
}));
