import { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';
import { MuiBadge } from '../../../../../modules/material';

export interface BadgeProps extends MuiBadgeProps {}

export default function Badge(props: BadgeProps) {
  const { color = 'primary', children, ...badgeProps } = props;

  return (
    <MuiBadge color={color} {...badgeProps}>
      {children}
    </MuiBadge>
  );
}
