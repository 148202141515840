import { useEffect } from 'react';
import { drawerClasses } from '@mui/material/Drawer';

function removeDoubleDrawer() {
  const renderedDrawers = document.querySelectorAll(`.${drawerClasses.root}`);

  if (renderedDrawers.length > 1) {
    renderedDrawers[0].setAttribute('hidden', 'true');
  }
}

// Fix for GEN-1414 this fixed double drawer attached to dom,
// when added inside old versioned React class component
export default function DoubleDrawerFixer() {
  useEffect(() => {
    setTimeout(() => {
      removeDoubleDrawer();
    }, 0);

    return () => {
      removeDoubleDrawer();
    };
  }, []);

  return null;
}
