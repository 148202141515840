import { styled } from '@mui/material/styles';
import Typography from '../../../../library/Typography';

export const StyledPackageDetailsWrapper = styled('div')({
  flexGrow: 1,
});

export const StyledRestProduct = styled(Typography)({
  overflow: 'hidden',
});
