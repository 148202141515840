import { styled } from '@mui/material/styles';
import Typography from '../../../../library/Typography';
import { MuiBox } from '../../../../../../../modules/material';

export const StyledTotalDiscount = styled(MuiBox)({
  width: '100%',
  textAlign: 'center',
  position: 'relative',
});

export const StyledTotalDiscountOverlay = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customSuccess.mainCustom,
  opacity: 0.2,
  borderRadius: '4px',
  position: 'absolute',
  width: '100%',
  height: '100%',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: '9px',
  color: theme.palette.customSuccess.darkCustom,
}));
