import { Trans } from 'react-i18next';
import { useRootSelector } from '../../../../../../../../store/hooks';
import { selectPricingPackagesCount } from '../../../../../../store/features/packages';
import { getTicketsCount } from '../../../../../../helpers/basket/getTicketsCount';
import Typography from '../../../../../library/Typography';
import { StyledTypography } from './styles';

export default function Title() {
  const packagesCount = useRootSelector(selectPricingPackagesCount);
  const ticketsCount = getTicketsCount(packagesCount);

  return (
    <StyledTypography
      component="div"
      variant="h5"
      className="informa-payment-mini-basket-title"
    >
      <Trans
        i18nKey="site.pricing.mini-basket.title"
        values={{ 0: ticketsCount }}
        components={[<Typography variant="body1" neutralColorIndex={600} />]}
      />
    </StyledTypography>
  );
}
