import {
  PricingPackage,
  PricingPackagePrice,
  PricingPackagesTotalPrice,
} from '../../../store/features/packages/types';

interface PricingDataProps extends Partial<PricingPackage> {
  currentPrice: PricingPackagePrice['amount'];
  currentPriceWithVat: PricingPackage['packagePriceWithVat'];
  originalPrice: PricingPackagePrice['originalPrice'];
  originalPriceWithVat: PricingPackage['originalPriceWithVat'];
  displayPriceWithVat: PricingPackagesTotalPrice['displayPriceWithVat'];
}

export function getPackagePriceDataByVat(props: PricingDataProps) {
  const {
    currentPrice,
    originalPrice,
    currentPriceWithVat,
    originalPriceWithVat,
    displayPriceWithVat,
  } = props;

  return {
    packageCurrentPrice: displayPriceWithVat
      ? currentPriceWithVat
      : currentPrice,
    packageOriginalPrice: displayPriceWithVat
      ? originalPriceWithVat
      : originalPrice,
  };
}
