import { styled } from '@mui/material/styles';
import { MuiStack } from '../../../../../../../../modules/material';
import Popper from '../../../../../library/Popper';

export const StyledPopper = styled(Popper)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  minWidth: '600px',
}));

export const StyledBody = styled(MuiStack)(() => ({
  paddingBottom: '16px',
}));

export const StyledFooter = styled('div')(({ theme }) => ({
  paddingTop: '16px',
  borderTop: `1px solid ${theme.palette.neutral[200]}`,
}));

export const StyledFooterContent = styled(MuiStack)(() => ({
  padding:
    '0 var(--payment-component-popper-padding) var(--payment-component-popper-padding)',
}));
