import { ReactNode } from 'react';
import { MuiStack } from '../../../../../../../../modules/material';
import { StyledCard, StyledCardOverlay, StyledPicture } from './styles';

export interface PackageCardProps {
  dateAndLocation?: ReactNode;
  picture?: ReactNode;
  details?: ReactNode;
  price?: ReactNode;
  numbersBar?: ReactNode;
  isMobile?: boolean;
  isOutdated?: boolean;
  hasBorder?: boolean;
}

export default function PackageCard(props: PackageCardProps) {
  const {
    hasBorder = true,
    isMobile,
    isOutdated,
    dateAndLocation,
    picture,
    details,
    price,
    numbersBar,
  } = props;

  const priceNumbersBar = (
    <MuiStack
      spacing={1}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      {numbersBar}
      {price}
    </MuiStack>
  );

  return (
    <StyledCard
      spacing={2}
      hasBorder={hasBorder}
      data-testid="mini-basket-package-card-wrapper"
    >
      {isOutdated && (
        <StyledCardOverlay data-testid="mini-basket-package-outdated-overlay" />
      )}
      <MuiStack spacing={2} direction="row" alignItems="stretch">
        <MuiStack>
          <StyledPicture isMobile={isMobile}>{picture}</StyledPicture>
        </MuiStack>
        <MuiStack spacing={1} flexGrow={1} justifyContent="space-between">
          <MuiStack>
            {dateAndLocation}
            {details}
          </MuiStack>
          {!isMobile && priceNumbersBar}
        </MuiStack>
      </MuiStack>
      {isMobile && priceNumbersBar}
    </StyledCard>
  );
}
