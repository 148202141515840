import { useTranslation } from 'react-i18next';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectOptions } from '../../../../../../store/features/options';
import Button, { ButtonProps } from '../../../library/Button';

export interface BasketCheckoutButtonProps extends ButtonProps {}

export default function BasketCheckoutButton(props: BasketCheckoutButtonProps) {
  const { t } = useTranslation();

  const {
    inGoConfig: { enabled: inGoEnabled },
  } = useRootSelector(selectOptions);

  const nextStepSubPath = inGoEnabled
    ? '/social-checkout'
    : '/confirm-purchase';

  return (
    <Button
      href={nextStepSubPath}
      pathPrefix="purchase"
      color="primary"
      fullWidth
      {...props}
    >
      {t('site.pricing.basket.button.checkout.label')}
    </Button>
  );
}
