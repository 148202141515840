import { PricingPackage } from '../../../../../../store/features/packages/types';
import { usePackageThumbnailData } from '../../../../../../hooks/basket/usePackageThumbnailData';
import { usePackageDateAndLocation } from '../../../../../../hooks/basket/usePackageDateAndLocation';
import { useRootSelector } from '../../../../../../../../store/hooks';
import { selectSitePricingPackagesMeta } from '../../../../../../store/features/packages';
import { getPackagePriceDataByVat } from '../../../../../../helpers/shared/getPackagePriceDataByVat';
import Typography from '../../../../../library/Typography';
import Date from '../../../../Date';
import Location from '../../../../Location';
import PriceInfo from '../../../../PriceInfo';
import Thumbnail from '../../../../Thumbnail';
import InlineList from '../../../../InlineList';
import NumbersBar from '../../../BasketCard/NumbersBar';
import PackageDetails from '../../../BasketCard/PackageDetails';
import PackageCard from '../PackageCard';

export interface PackagesByDelegateProps {
  packageList: PricingPackage[];
  outdated?: boolean;
  mobile?: boolean;
}

export default function PackagesByDelegate(props: PackagesByDelegateProps) {
  const { packageList, outdated, mobile } = props;

  const { backgroundImageUrl, whiteLogoUrl, siteTitle } =
    usePackageThumbnailData();
  const { date: packageDate, location } = usePackageDateAndLocation();
  const {
    totalPrice: { displayPriceWithVat },
  } = useRootSelector(selectSitePricingPackagesMeta);

  return (
    <div className="informa-payment-mini-basket-package">
      {packageList.map((delegatePackage, delegatePackageIndex) => {
        const {
          id,
          packageSelection,
          quantity,
          packagePriceWithVat,
          originalPriceWithVat,
          totalPriceExVat: { amount, originalPrice: originalPriceExVat },
        } = delegatePackage;

        const { packageCurrentPrice, packageOriginalPrice } =
          getPackagePriceDataByVat({
            originalPrice: originalPriceExVat,
            originalPriceWithVat,
            currentPrice: amount,
            currentPriceWithVat: packagePriceWithVat,
            displayPriceWithVat,
          });

        const isLastDelegate = packageList.length - 1 === delegatePackageIndex;

        return (
          <PackageCard
            key={packageSelection[0].productName + delegatePackageIndex}
            isMobile={mobile}
            isOutdated={outdated}
            hasBorder={!isLastDelegate}
            dateAndLocation={
              (packageDate || location) && (
                <InlineList
                  list={[
                    packageDate && (
                      <Date
                        neutralColorIndex={700}
                        sx={{ textTransform: 'uppercase' }}
                        className="informa-payment-mini-basket-package__date"
                      >
                        {packageDate}
                      </Date>
                    ),
                    location && (
                      <Location
                        location={location}
                        className="informa-payment-mini-basket-package__location"
                      />
                    ),
                  ]}
                />
              )
            }
            picture={
              <Thumbnail
                backgroundImageUrl={backgroundImageUrl}
                foregroundImageUrl={whiteLogoUrl}
              >
                {!whiteLogoUrl && (
                  <Typography
                    variant="caption1"
                    neutralColorIndex={0}
                    className="informa-payment-mini-basket-package__site-title"
                  >
                    {siteTitle}
                  </Typography>
                )}
              </Thumbnail>
            }
            details={
              <PackageDetails
                packageSelection={packageSelection}
                primaryProductProps={{
                  sx: { typography: 'h5' },
                }}
                restProductsProps={{
                  sx: { typography: 'smallText' },
                }}
              />
            }
            price={
              <PriceInfo
                price={packageCurrentPrice}
                startPrice={packageOriginalPrice}
                isReverseOrderEnabled
                variant="featuredText"
              />
            }
            numbersBar={<NumbersBar quantity={quantity} packageId={id} />}
          />
        );
      })}
    </div>
  );
}
