import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { useRootSelector } from '../../../../store/hooks';
import { selectPageConfig } from '../../../../store/features/pageConfig';
import {
  concatPath,
  isAbsoluteUrl,
  isFileUrl,
  isMailToUrl,
  isTelephoneUrl,
} from '../../../../helpers';
import { localizePath } from '../../../../helpers/locale';
import { selectOptions } from '../../../../store/features/options';

export interface UseCustomizedSiteURLProps {
  url?: string;
  pathPrefix?: string | string[];
  query?: Record<string, any>;
  disableSitePath?: boolean;
}

export function useCustomizedSiteURL(props: UseCustomizedSiteURLProps) {
  const { i18n } = useTranslation();
  const { url, pathPrefix, query, disableSitePath } = props;
  const { sitePath } = useRootSelector(selectPageConfig);
  const {
    locales: { primary: primaryLocale },
  } = useRootSelector(selectOptions);

  const isExternalURL = useMemo(
    () =>
      isAbsoluteUrl(url) ||
      isFileUrl(url) ||
      isMailToUrl(url) ||
      isTelephoneUrl(url),
    [url],
  );
  const queryString = useMemo(
    () => qs.stringify(query, { addQueryPrefix: true }),
    [query],
  );

  if (!url) return;

  if (isExternalURL) return `${url}${queryString}`;

  const pathPrefixArray = Array.isArray(pathPrefix) ? pathPrefix : [pathPrefix];
  const sitePathPrefix = disableSitePath ? '' : sitePath;
  const customizedUrl = concatPath(
    [sitePathPrefix, ...pathPrefixArray].concat([url]),
  );
  const finalUrl = `${customizedUrl}${queryString}`;

  return disableSitePath
    ? finalUrl
    : localizePath(finalUrl, i18n.language, primaryLocale);
}
