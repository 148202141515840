import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const StyledLocationOnIcon = styled(LocationOnIcon)({
  fontSize: 14,
  lineHeight: 18,
  height: '16px',
  top: '2px',
  marginLeft: '-4px',
  position: 'relative',
});
