import { TypographyProps } from '../../library/Typography';
import { StyledPrice } from './styles';
import cn from 'classnames';

export interface PriceProps extends TypographyProps {
  lineThrough?: boolean;
}

export default function Price(props: PriceProps) {
  const {
    variant = 'featuredText',
    component = 'span',
    neutralColorIndex = 800,
    children,
    ...priceProps
  } = props;

  return (
    <StyledPrice
      variant={variant}
      component={component}
      neutralColorIndex={neutralColorIndex}
      {...priceProps}
      className={cn(priceProps.className, 'informa-payment-price')}
    >
      {children}
    </StyledPrice>
  );
}
