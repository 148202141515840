import { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { StyledIconButton } from './styles';

export enum IconButtonVariants {
  square = 'square',
  circular = 'circular',
  icon = 'icon',
}

export interface IconButtonProps extends MuiIconButtonProps {
  variant?: keyof typeof IconButtonVariants;
}

export default function IconButton(props: IconButtonProps) {
  const {
    variant = IconButtonVariants.square,
    size = 'medium',
    children,
    ...buttonProps
  } = props;

  return (
    <StyledIconButton variant={variant} size={size} {...buttonProps}>
      {children}
    </StyledIconButton>
  );
}
