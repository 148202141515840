import { HTMLAttributes } from 'react';
import { styled } from '@mui/material/styles';

const InlineListOptions = {
  shouldForwardProp: (prop: string) => !['separator'].includes(prop),
};

export const StyledInlineList = styled(
  'ul',
  InlineListOptions,
)<HTMLAttributes<HTMLUListElement> & { separator?: string }>(
  ({ separator }) => ({
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'wrap',

    '& li': {
      display: 'inline-block',
      verticalAlign: 'middle',

      '&:not(:last-child)::after': {
        content: `"${separator}"`,
        opacity: 0.7,
        padding: '0 8px 0 4px',
      },
    },
  }),
);
