import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { StyledButton } from './styles';
import {
  useCustomizedSiteURL,
  UseCustomizedSiteURLProps,
} from '../../../hooks/useCustomizedSiteURL';
import { forwardRef, Ref } from 'react';

export enum ButtonShapes {
  square = 'square',
  rounded = 'rounded',
}

export enum ButtonColorMode {
  light = 'light',
  dark = 'dark',
}

export interface ButtonProps
  extends Omit<UseCustomizedSiteURLProps, 'url'>,
    MuiButtonProps {
  shape?: keyof typeof ButtonShapes;
  colorMode?: keyof typeof ButtonColorMode;
}

const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const {
    type = 'button',
    variant = 'contained',
    color = 'primary',
    shape = ButtonShapes.square,
    colorMode = ButtonColorMode.dark,
    children,
    href,
    pathPrefix,
    query,
    disableSitePath,
    ...buttonProps
  } = props;

  const linkUrl = useCustomizedSiteURL({
    url: href,
    disableSitePath,
    pathPrefix,
    query,
  });

  return (
    <StyledButton
      type={type}
      variant={variant}
      color={color}
      shape={shape}
      colorMode={colorMode}
      {...buttonProps}
      href={linkUrl}
      ref={ref}
    >
      {children}
    </StyledButton>
  );
});

export default Button;
