import { PropsWithChildren } from 'react';
import {
  StyledThumbnailWrapper,
  StyledThumbnailForeground,
  StyledThumbnailBackground,
  StyledThumbnailCover,
  StyledThumbnailContent,
} from './styles';

export interface ThumbnailProps extends PropsWithChildren {
  backgroundImageUrl?: string;
  foregroundImageUrl?: string;
}

export default function Thumbnail(props: ThumbnailProps) {
  const { backgroundImageUrl, foregroundImageUrl, children } = props;

  return (
    <StyledThumbnailWrapper className="informa-payment-thumbnail">
      <StyledThumbnailCover data-testid="thumbnail-cover" />
      <StyledThumbnailBackground
        bgImageUrl={backgroundImageUrl}
        data-testid="thumbnail-background"
      />
      {foregroundImageUrl && (
        <StyledThumbnailForeground
          bgImageUrl={foregroundImageUrl}
          data-testid="thumbnail-foreground"
        />
      )}
      <StyledThumbnailContent className="informa-payment-thumbnail__content">
        {children}
      </StyledThumbnailContent>
    </StyledThumbnailWrapper>
  );
}
