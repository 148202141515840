import { styled } from '@mui/material/styles';
import { drawerClasses } from '@mui/material';
import { MuiStack } from '../../../../../../../../modules/material';
import Drawer from '../../../../../library/Drawer';

export const StyledDrawer = styled(Drawer)(() => ({
  [`& .${drawerClasses.paper}`]: {
    borderRadius: '4px 4px 0 0',
  },
}));

export const StyledContainer = styled(MuiStack)(() => ({
  padding: '16px',
}));
