import { ReactNode, HTMLAttributes } from 'react';
import { StyledInlineList } from './styles';

export interface InlineListProps extends HTMLAttributes<HTMLUListElement> {
  list: ReactNode[];
  separator?: string;
}

export default function InlineList(props: InlineListProps) {
  const { list, separator = ' • ', ...inlineListProps } = props;

  if (!list.length) return null;

  return (
    <StyledInlineList {...inlineListProps} separator={separator}>
      {list.filter(Boolean).map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </StyledInlineList>
  );
}
