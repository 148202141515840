import { useDeviceDetect } from '../../../../../../hooks';
import MobileLayout from './layouts/MobileLayout';
import DesktopLayout from './layouts/DesktopLayout';

export interface MiniBasketProps {
  open: boolean;
  buttonAnchorEl: HTMLButtonElement | null;
  onClose: () => void;
}

export default function MiniBasket(props: MiniBasketProps) {
  const { open, buttonAnchorEl, onClose } = props;

  const { isDesktop } = useDeviceDetect();

  return (
    <>
      <DesktopLayout
        open={isDesktop && open}
        anchorEl={buttonAnchorEl}
        onClose={onClose}
      />
      <MobileLayout open={!isDesktop && open} onClose={onClose} />
    </>
  );
}
