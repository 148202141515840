import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import IconButton from '../../../../library/IconButton';
import TextInput from '../../../../library/TextInput';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginTop: '1px',
  borderRadius: '2px',
  padding: '5px',

  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
}));

export const StyledNumberInputField = styled(TextInput)(({ theme }) => ({
  padding: '0',
  borderRadius: '2px',

  [`.${inputBaseClasses.root}`]: {
    padding: 0,
    fontSize: '16px',

    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },

  '& input': {
    padding: '0 2px 0 14px',
    width: '42px',
    height: '32px',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      padding: '5px 8px 5px 18px',
    },
  },
}));
