import { ForwardedRef, forwardRef } from 'react';
import { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import { Palette } from '@mui/material/styles';

import { PaddingVariants, StyledPaper } from './styles';

export interface PaperProps extends MuiPaperProps {
  neutralBackgroundColor?: keyof Palette['neutral'];
  backgroundRGBColor?: string;
  opacity?: number;
  enableBorder?: boolean;
  neutralBorderColor?: keyof Palette['neutral'];
  isInline?: boolean;
  paddingVariant?: keyof typeof PaddingVariants;
}

const Paper = forwardRef(function Paper(
  props: PaperProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    paddingVariant = PaddingVariants.common,
    neutralBackgroundColor = 0,
    neutralBorderColor = 200,
    children,
    ...paperProps
  } = props;

  return (
    <StyledPaper
      paddingVariant={paddingVariant}
      neutralBackgroundColor={neutralBackgroundColor}
      neutralBorderColor={neutralBorderColor}
      ref={ref}
      {...paperProps}
    >
      {children}
    </StyledPaper>
  );
});

export default Paper;
