import { styled } from '@mui/material/styles';
import { MuiPaper } from '../../../../../modules/material';
import { PaperProps } from './index';

export enum PaddingVariants {
  none = 'none',
  small = 'small',
  common = 'common',
  extraSmallTopBottom = 'extraSmallTopBottom',
  smallTopBottom = 'smallTopBottom',
  smallRightLeft = 'smallRightLeft',
}

const getPaddingValues = (variant: keyof typeof PaddingVariants) => {
  switch (variant) {
    case PaddingVariants.none:
      return '0';
    case PaddingVariants.common:
      return 'var(--payment-component-paper-padding)';
    case PaddingVariants.small:
      return 'var(--payment-component-paper-padding-small)';
    case PaddingVariants.smallTopBottom:
      return 'var(--payment-component-paper-padding-small) var(--payment-component-paper-padding)';
    case PaddingVariants.extraSmallTopBottom:
      return 'var(--payment-component-paper-padding-extra-small) var(--payment-component-paper-padding)';
    case PaddingVariants.smallRightLeft:
      return 'var(--payment-component-paper-padding) var(--payment-component-paper-padding-small)';
  }
};

const styledPaperOptions = {
  shouldForwardProp: (prop: string) =>
    ![
      'paddingVariant',
      'neutralBackgroundColor',
      'backgroundRGBColor',
      'opacity',
      'neutralBorderColor',
      'isInline',
      'enableBorder',
    ].includes(prop),
};

export const StyledPaper = styled(
  MuiPaper,
  styledPaperOptions,
)<PaperProps>((props) => {
  const {
    paddingVariant,
    neutralBackgroundColor,
    backgroundRGBColor,
    opacity,
    neutralBorderColor,
    isInline,
    enableBorder,
    square,
    theme,
  } = props;

  return {
    display: isInline ? 'inline-block' : 'block',
    borderRadius: square ? 0 : 'var(--payment-component-paper-border-radius)',
    boxShadow: '0 4px 16px 0 rgba(8, 33, 66, 0.05)',
    position: 'relative',
    ...(neutralBackgroundColor && {
      backgroundColor: theme.palette.neutral[neutralBackgroundColor],
    }),
    ...(backgroundRGBColor && {
      backgroundColor: `rgba(${backgroundRGBColor}, ${opacity})`,
    }),
    ...(paddingVariant && {
      padding: getPaddingValues(paddingVariant),
    }),
    ...(enableBorder &&
      neutralBorderColor && {
        border: `1px solid ${theme.palette.neutral[neutralBorderColor]}`,
      }),
  };
});
