import { Trans } from 'react-i18next';
import { BoxProps as MuiBoxProps } from '@mui/material/Box';
import cn from 'classnames';
import {
  StyledTotalDiscount,
  StyledTotalDiscountOverlay,
  StyledTypography,
} from './styles';

export interface OrderTicketCountProps extends MuiBoxProps {
  totalDiscount: string;
}

export default function OrderDiscount(props: OrderTicketCountProps) {
  const { totalDiscount, ...restProps } = props;

  return (
    <StyledTotalDiscount
      {...restProps}
      className={cn(restProps.className, 'informa-payment-order-discount')}
    >
      <StyledTotalDiscountOverlay />
      <StyledTypography
        variant="caption2"
        component="div"
        sx={{ mt: 0 }}
        className="informa-payment-order-discount__title"
      >
        <Trans
          i18nKey="site.pricing.order-summary.total-discount.title"
          values={{ 0: totalDiscount }}
          components={{ bold: <strong /> }}
        />
      </StyledTypography>
    </StyledTotalDiscount>
  );
}
