import cn from 'classnames';
import { PopperProps } from '../../../../../library/Popper';
import { MuiStack } from '../../../../../../../../modules/material';
import Title from '../../components/Title';
import CloseButton from '../../components/CloseButton';
import Discount from '../../components/Discount';
import TotalPrice from '../../components/TotalPrice';
import BasketViewFullButton from '../../../BasketViewFullButton';
import BasketCheckoutButton from '../../../BasketCheckoutButton';
import MiniBasketPackagesList from '../../components/MiniBasketPackagesList';

import {
  StyledBody,
  StyledFooter,
  StyledFooterContent,
  StyledPopper,
} from './styles';

export interface DesktopLayoutProps extends Omit<PopperProps, 'children'> {
  onClose: () => void;
}

export default function DesktopLayout(props: DesktopLayoutProps) {
  const { onClose, ...popperProps } = props;

  return (
    <StyledPopper
      onClose={onClose}
      footer={
        <StyledFooter>
          <StyledFooterContent spacing={2}>
            <TotalPrice />
            <MuiStack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <BasketViewFullButton />
              <BasketCheckoutButton />
            </MuiStack>
          </StyledFooterContent>
        </StyledFooter>
      }
      {...popperProps}
      className={cn(popperProps.className, 'informa-payment-mini-basket')}
    >
      <StyledBody spacing={2}>
        <MuiStack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Title />
          <CloseButton onClick={onClose} />
        </MuiStack>
        <MuiStack spacing={0.5}>
          <Discount />
          <MiniBasketPackagesList />
        </MuiStack>
      </StyledBody>
    </StyledPopper>
  );
}
