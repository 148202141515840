import { createContext, useContext, PropsWithChildren, useState } from 'react';
import ThemeProvider from '../../muiTheme/ThemeProvider';

export type PaymentContextType = {
  isBasketFirstTimeAdded: boolean;
  setIsBasketFirstTimeAdded: (value: boolean) => void;
};

export const PaymentContext = createContext({} as PaymentContextType);

export default function PaymentProvider(props: PropsWithChildren) {
  const { children } = props;

  const [isBasketFirstTimeAdded, setIsBasketFirstTimeAdded] = useState(false);

  return (
    <ThemeProvider>
      <PaymentContext.Provider
        value={{ isBasketFirstTimeAdded, setIsBasketFirstTimeAdded }}
      >
        {children}
      </PaymentContext.Provider>
    </ThemeProvider>
  );
}

export function usePaymentContext() {
  return useContext<PaymentContextType>(PaymentContext);
}
