import { styled } from '@mui/material/styles';
import { MuiButton } from '../../../../../modules/material';
import { ButtonColorMode, ButtonProps, ButtonShapes } from './index';

const styledButtonOptions = {
  shouldForwardProp: (prop: string) => !['colorMode', 'shape'].includes(prop),
};

export const StyledButton = styled(
  MuiButton,
  styledButtonOptions,
)<ButtonProps>((props) => {
  const { theme, shape, colorMode } = props;

  return {
    borderRadius:
      shape === ButtonShapes.square
        ? 'var(--payment-component-button-shape-square-border-radius)'
        : 'var(--payment-component-button-shape-rounded-border-radius)',

    ...(colorMode === ButtonColorMode.light && {
      color: theme.palette.neutral[0],
    }),
  };
});
