import Typography, { TypographyProps } from '../../library/Typography';

export interface DateProps extends TypographyProps {}

export default function Date(props: DateProps) {
  const {
    variant = 'caption1',
    component = 'span',
    fontWeight = 700,
    opacity = 0.7,
    neutralColorIndex = 700,
    children,
    ...dateProps
  } = props;

  return (
    <Typography
      variant={variant}
      component={component}
      fontWeight={fontWeight}
      opacity={opacity}
      neutralColorIndex={neutralColorIndex}
      {...dateProps}
    >
      {children}
    </Typography>
  );
}
