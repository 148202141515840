import CloseIcon from '@mui/icons-material/Close';
import IconButton, { IconButtonProps } from '../../../../../library/IconButton';

export interface CloseButtonProps extends IconButtonProps {}

export default function CloseButton(props: CloseButtonProps) {
  return (
    <IconButton variant="icon" {...props}>
      <CloseIcon />
    </IconButton>
  );
}
